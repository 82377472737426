import {ref} from 'vue';

export const showIframe = ref(false);

export const source = ref();

export async function OpenBottom(src){

showIframe.value = true;

source.value = src;

 }